.header-centered-right-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  margin-left: 100px;

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-top: 40px;
    justify-content: flex-start;
  }
}

.load-more-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  .generic-button {
    width: 200px;
    background-color: #e72313;
    height: 30px;
  }
}