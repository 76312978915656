.htl-section {
  display: flex;
  flex-direction: column;

  .container{
    display: flex;
    flex-direction: column;

    .section {
      margin-bottom:3em;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      line-height: 22px;

      a {
        color: white;
        font-weight: 600;
      }

      strong {
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 0.1em;
        display: block;
      }

      span.hidden {
        filter: blur(0.5em);
        cursor: pointer;
        transition: filter 0.4s ease-out;

        &.show {
          filter: blur(0);
          cursor: text;
        }
      }
    }
  }
}