.countdown-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid #ffffff44;
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 40px;

  .header {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    letter-spacing: 0.1em;
  }

  .countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #e72313;
      border-radius: 10px;
      margin: 0 10px;
      user-select: none;
      margin-bottom: 20px;

      .value {
        font-family: 'Poppins', sans-serif;
        font-size: 40px;
        color: #fff;
        // letter-spacing: 0.1em;
        padding: 10px 0;
      }

      .label {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: #fff;
        letter-spacing: 0.1em;
        background: #00000022;
        width: 80px;
        text-align: center;
        padding: 10px;
      }
    }
  }
}